.base-wrapper {
    .bottom-style {
        border-bottom: 1px solid #dddddd;
        padding: 5px 0 5px;
    }
    .ant-checkbox-wrapper + span,
    .ant-checkbox + span {
        padding-left: 8px;
        padding-right: 8px;
        min-width: 87px;
        display: inline-block;
    }
    .ant-select {
        max-width: 180px !important;
    }
    .input-width {
        max-width: 180px;
    }
}

.popconfirm-button {
    .ant-popover-buttons {
        display: none;
    }
}

@primary-color: #FFBA30;@link-color: #FFBA30;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 12px;@layout-header-background: #191919;@layout-body-background: #eee;@zindex-message: 9999;@btn-height-base: 26px;@input-height-base: 26px;@select-height-base: 26px;@menu-collapsed-width: 40px;@layout-header-height: 46px;@disabled-color: fade(#000, 55%);