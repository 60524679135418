.tableview-box {
    padding: 0 10px;
    margin: 1px;
    .table-action-num {
        padding-left: 0 !important;
    }
}

.react-resizable {
    position: relative;
}

.react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
}

.ant-pagination-options-size-changer.ant-select {
    margin-right: 0;
}

#colunm-widtd-render {
    opacity: 0;
    position: absolute;
    top: -50px;
    left: 0;
}

// .global_table_setting_list{

// }

.global_table_setting_item {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    border-top: 1px solid #d7d7d7;
    position: relative;
    cursor: move;

    &:nth-of-type(1) {
        cursor: default;
    }

    .divider {
        position: relative;
        margin-left: 10px;

        &::before {
            position: absolute;
            left: -10px;
            top: 50%;
            margin-top: -10px;
            content: "";
            width: 1px;
            height: 20px;
            background-color: #d6d6d6;
        }
    }

    .name {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: space-between;

        .text {
            // height: 18px;
            padding: 0 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .setting {
        margin-left: 10px;
    }
}

@primary-color: #FFBA30;@link-color: #FFBA30;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 12px;@layout-header-background: #191919;@layout-body-background: #eee;@zindex-message: 9999;@btn-height-base: 26px;@input-height-base: 26px;@select-height-base: 26px;@menu-collapsed-width: 40px;@layout-header-height: 46px;@disabled-color: fade(#000, 55%);