/* input placeholder color start */
::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #aaa;
}
:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #aaa;
}
::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #aaa;
}
:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #aaa;
}
/* input placeholder color end */
html {
    overflow: auto;
}
body {
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-family: "PingFang SC", "Helvetica Neue", Helvetica, "Hiragino Sans GB", "Microsoft YaHei",
        "\5FAE\8F6F\96C5\9ED1", Arial, sans-serif;
    background: #f0f2f5;
    height: auto !important;
}
body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
	display: none;
}


.ML__keyboard.is-visible {
    z-index: 1000;
}
// .ant-switch {
//     background-color: rgba(0, 0, 0, 0.2);
// }
.con_scroll > main {
    background-color: white;
}

.ant-table-thead > tr > th.table-action,
.ant-table-tbody > tr > td.table-action {
    text-align: center;
}

.ant-cascader-menu-item-active {
    color: #019bf0;
}

html .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
html
    .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-thead
    > tr
    > th {
    background: #f8f8f8;
}

html .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
html
    .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-tbody
    > tr
    > td {
    padding: 6px 8px;
}

html .ant-table-title {
    padding: 8px 0;
}

html .ant-select-dropdown-menu {
    max-height: 200px;
}

html .ant-spin-nested-loading > div > .ant-spin {
    background: rgba(246, 246, 246, 0.6);
    max-height: 1000px;
}

html .ant-table-thead > tr > th {
    // border-bottom: 1px solid #ddd  !important;
    // border-top: 1px solid #ddd !important;
    background: #f8f8f8;
    color: #888888;

    &:hover {
        background: #eee;
    }
}

html .ant-table-thead .split-table-style {
    border-top: 0 !important;
}

html .ant-table-fixed-right,
.ant-table-fixed-right table {
    border-radius: 0;
    //z-index: 2;
}

html .ant-table-title + .ant-table-content {
    border-radius: 0;
}

html .ant-table.ant-table-bordered .ant-table-title {
    border: 0;
    padding: 5px 0;
}

/* 表格 td 样式 */
.ant-table-bordered .ant-table-thead > tr > th,
html .ant-table-bordered .ant-table-tbody > tr > td {
    padding: 5px 10px;
    position: relative;
}

.table-action-box > button,
.table-action-box > span {
    margin: 0 5px;
}

.ant-table-thead > tr > th.table-action-box,
.table-action-box {
    text-align: center;
}

html .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #ddd;
}

html .ant-table-thead > tr > th.ant-table-selection-column,
html .ant-table-tbody > tr > td.ant-table-selection-column {
    min-width: 45px;
    padding: 0;
    width: 45px;
}

html .ant-table-bordered .ant-table-thead > tr.table-action-num {
    padding: 0;
}

html .tableview-box .ant-table-pagination.ant-pagination {
    margin: 10px 0;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.form-tooltip .ant-popover-inner-content {
    padding: 3px 4px;
}

.ant-tabs-tab-next {
    background: white;
}

.color-red {
    color: rgb(229, 28, 35);
}

.color-green {
    color: #1da57a;
}

.color-yellow {
    color: #ff5722;
}

.err-valid-table table {
    position: relative;
}

html .ant-modal-wrap {
    z-index: 1010;
}

html .ant-modal-mask {
    z-index: 1010;
}

.ant-btn {
    min-width: 60px;
}

html {
    .ant-table th {
        white-space: nowrap;
    }

    .ant-form-item-control {
        line-height: 0;
    }

    .btn-success {
        background: @primary-color;
        color: #fff;

        &:hover,
        &:focus {
            background: @primary-color;
            color: #fff;
        }
    }

    .btn-padding-20 {
        padding: 0 20px;
    }

    .btn-padding-30 {
        padding: 0 30px;
    }

    // InputNumber增减操作z-index
    form .ant-input-number-handler-wrap {
        z-index: 0;
    }
}

html .ant-radio-group-small .ant-radio-button-wrapper {
    min-width: 60px;
    text-align: center;
}

// ------------------------------public--------------------------
/* 单行显示超出省略号 start */
.text-overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
/* 单行显示超出省略号 end */

/* 阴影使用 start */
.paper-1 {
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.117647), 0 1px 4px rgba(0, 0, 0, 0.117647);
}

.paper-2 {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.156863), 0 3px 10px rgba(0, 0, 0, 0.227451);
}
.paper-3,
.paper-4 {
    box-shadow: 0 14px 45px rgba(0, 0, 0, 0.247059), 0 10px 18px rgba(0, 0, 0, 0.219608);
}
.paper-5 {
    box-shadow: 0 19px 60px rgba(0, 0, 0, 0.298039), 0 15px 20px rgba(0, 0, 0, 0.219608);
}
/* 阴影使用 end */

/* flex box start */
.flex {
    display: -webkit-flex; /* Safari */
    display: flex;
}
// 方向垂直
.flex-direction-reverse {
    flex-direction: row-reverse;
}
// 方向横向
.flex-direction-column {
    flex-direction: column;
}
// 横向不换行
.flex-nowrap {
    flex-wrap: nowrap;
}
// 横向换行
.flex-wrap {
    flex-wrap: wrap;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}

.flex-left {
    justify-content: flex-start;
}
.flex-right {
    justify-content: flex-end;
}
// 水平居中
.flex-level-center {
    justify-content: center;
}
// 垂直居中
.flex-vertical-center {
    align-items: center;
}
.flex1 {
    flex: 1;
}
/* flex box end */

.h100 {
    height: 100%;
}
.w100 {
    width: 100%;
}
.text-center {
    text-align: center;
}

.default-background {
    background: @layout-body-background;
}

.table-action-num {
    min-width: 40px;
    // max-width: 100px;
    width: 45px;
}
.table-action-action {
    min-width: 50px;
}

.index-list-table-style {
    background-color: #fff;
    // box-shadow: 0 1px 4px 0 rgba(0,0,0,0.2);
    // margin: 0 10px;
    // padding: 0 10px;
}

.loading-page {
    width: 100%;
    height: 100%;
    min-width: 600px;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .ant-spin-dot {
        margin-bottom: 12px;
    }
}

// 费用项弹窗选择费用弹框
.cost-item-view {
    margin: 5px 0;
}

// 按钮文字样式
.action-button {
    color: @primary-color !important;
    cursor: pointer;
    //margin: 0 10px;
    font-size: 12px;
    margin-right: 10px;
    &:last-child {
        margin-right: 0;
    }
}

.primary-color {
    color: @primary-color;
}

// /* 滚动条样式 */
// *::-webkit-scrollbar{
//     // display: none;
//     width: 12px !important;
//     height: 12px !important;
// }
// *::-webkit-scrollbar-thumb {
//     /*滚动条里面小方块*/
//     border-radius: 5px !important;
//     -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2) !important;
//     box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) inset !important;
//     background: rgba(0, 0, 0, 0.2) !important;
// }
// *::-webkit-scrollbar-track {
//     /*滚动条里面轨道*/
//     -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2) !important;
//     box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) inset !important;
//     border-radius: 4px !important;
//     background: rgba(0, 0, 0, 0.1) !important;
// }
// /* 滚动条样式 */

// 表格 td 默认active激活样式
.cus-table-cell-active {
    background-color: #e4f5ed;
}

// 卡片阴影块样式
.sd-block {
    background-color: #fff;
    // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}

// 页面列表表格
.page-table {
    margin: 0 10px;
}

// 无 padding table
.itable-nopad {
    .ant-table.ant-table-bordered .ant-table-title {
        padding: 0;
    }
}

// 表格顶部按钮样式
.tb-head-btn {
    margin-right: 10px;
    vertical-align: middle;
}

// 自定义表格分页样式
.tb-custom-pagination {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
}

// 激活状态下划线tabs
.tabs-bottom-line {
    & > .ant-tabs-bar {
        margin-bottom: 5px;
    }
}

// 自定义card样式tabs
.tabs-custom-card {
    & > .ant-tabs-bar {
        height: 36px;
        margin-bottom: 5px;

        .ant-tabs-nav {
            padding: 0 24px;
            font-size: 12px;

            .ant-tabs-tab {
                height: 36px;
                line-height: 36px;
                color: #999;
            }

            .ant-tabs-tab-active {
                // box-shadow: 0 -4px 0 0 @primary-color  inset;
                color: @primary-color;
            }
        }
    }

    &.haspad {
        & > .ant-tabs-content {
            & > .ant-tabs-tabpane {
                padding: 0 20px;
            }
        }
    }

    &.nopad {
        & > .ant-tabs-content {
            & > .ant-tabs-tabpane {
                padding: 0;
            }
        }
    }

    .plusStyle {
        color: @primary-color;
        font-size: 14px;
        border: 1px solid @primary-color;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        cursor: pointer;
        text-align: center;
        margin: 2px 6px;
        line-height: 18px;
        display: inline-block;
    }
}

// tab添加按钮 popconfirm 自定义样式
.popconfirm-tab-plus {
    .ant-popover-inner-content {
        .ant-popover-message-title {
            padding-left: 0;
            .ant-menu {
                border-right: none;
                .ant-menu-item {
                    margin: 0;
                }
            }
        }
    }
}

// 表格 popover 更多icon 样式
td.itbcol-more {
    vertical-align: middle;
    span.more {
        margin-left: 6px;
        cursor: pointer;
        display: inline-block;
        border: 1px solid @primary-color;
        width: 14px;
        height: 14px;
        color: @primary-color;
        line-height: 14px;
        text-align: center;
        font-size: 12px;
    }
}

// 表格 承运商列 popover 更多显示
.ipopover-carrier-more {
    ul {
        margin: 0;
        padding: 0;
        li {
            display: flex;
            height: 32px;
            line-height: 32px;
            align-items: center;
            justify-content: space-between;
            color: #333;
            &:first-child {
                border-bottom: 1px solid #e1e1e1;
                color: #808080;
            }
            & > div {
                margin-right: 8px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                &:nth-of-type(1) {
                    width: 60px;
                }
                &:nth-of-type(2),
                &:nth-of-type(3),
                &:nth-of-type(4) {
                    width: 100px;
                }
                &:first-child {
                    margin-right: 0px;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

// 表格 发货单列 popover 更多显示
.ipopover-order-more {
    ul {
        margin: 0;
        padding: 0;
        li {
            height: 30px;
            line-height: 30px;
            color: #333;
            // &:first-child{
            //     border-bottom: 1px solid #e1e1e1;
            //     color: #808080;
            // }
        }
    }
}

// 现结价 表格样式
.itb-expense-cash {
    padding: 10px;
    background-color: #f7f7f7;
    .ant-table-thead > tr > th {
        // background-color: #fff;
        padding: 8px !important;
    }
    .ant-table-fixed-right {
        box-shadow: none;
    }
}

// 表格筛选下拉弹窗样式
.ant-table-filter-dropdown-link {
    border-top: none;
    line-height: 1.8;
    height: auto;
}

// 自定义右侧抽屉样式
.page-custom-right-drawer {
    @headHeight: 94px;
    .ant-drawer-content-wrapper {
        box-shadow: none !important;
        .ant-drawer-content {
            background: transparent;
            .ant-drawer-wrapper-body {
                margin-top: @headHeight;
                height: calc(100% - @headHeight) !important;
                background-color: #fff;
                box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
            }
        }
    }
}

// 自定义抽屉样式
._drawer-mask {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 200ms;

    ._drawer-container {
        background-color: #fff;
        height: 100%;
        width: 300px;
        position: relative;
        transition: all 200ms;
        box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.22) inset;

        .drawer-btn {
            position: absolute;
            width: 14px;
            height: 40px;
            background-color: rgba(0, 0, 0, 0.55);
            line-height: 40px;
            text-align: center;
            font-size: 12px;
            color: #fff;
            top: 50%;
            margin-top: -20px;
            right: -14px;
            cursor: pointer;
            transition: left 200ms;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 3px;
            padding-right: 0;
            border-radius: 0 4px 4px 0;

            &:hover {
                &::before,
                &::after {
                    border-color: @primary-color;
                }
            }

            &::before,
            &::after {
                content: "";
                width: 5px;
                height: 5px;
                border-left: 1px solid #fff;
                border-bottom: 1px solid #fff;
                transform: rotate(45deg);
            }
        }
    }

    &.show {
        z-index: 10;
        opacity: 1;

        ._drawer-container {
            transform: translateX(0);
        }
    }

    &.hide {
        z-index: -2;
        opacity: 0;

        ._drawer-container {
            transform: translateX(-100%);
        }
    }
}

// 自定义spin
._spin {
    width: 100%;
    min-height: 100px;
    position: relative;
    height: 100%;

    &::before {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(250, 250, 250, 0.36);
    }
}

// NB绑定配置
.nbbindtype-transfer {
    .ant-transfer-list-content-item {
        display: flex;
        flex-grow: 0;

        & > span {
            display: inline-block;
            width: calc(100% - 16px);
        }
        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-grow: 0;
            width: 100%;

            span:last-child {
                overflow: hidden;
                text-overflow: ellipsis;
                flex-grow: 1;
            }
        }
    }
}

// 限制最大宽度页面默认样式
.default-page-limit-width {
    max-width: 1120px;
    min-height: 300px;
    margin: 0 auto 5px;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
}

// -----layout--------
.home-left-sider {
    min-width: auto !important;
    transition: all 200ms;
    // background: white;
    user-select: none;
    // top: 0;
    height: 100vh;
    overflow-x: hidden;
    // position: fixed;
    // left: 0;
    padding-top: @layout-header-height;
    z-index: 9;

    .ant-menu-item,
    .ant-menu-submenu-title {
        font-size: 14px;
    }

    .ant-layout-sider-trigger {
        border-top: 1px solid #e1e1e1;
    }

    .tree-menu {
        width: 100%;
        height: 100%;
        // border-right: 0;
        overflow-y: auto;
        padding-bottom: 10px;
        position: fixed;
        top: 46px;
        left: 0;
        padding-bottom: 100px;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            display: none !important;
            width: 0 !important;
            height: 0 !important;
            -webkit-appearance: none;
            opacity: 0 !important;
        }
    }

    .nav-m-icon-s {
        // vertical-align: sub;
        margin-top: -6px;
    }

    .more {
        position: relative;

        &::before {
            content: "";
            height: 30px;
            width: 1px;
            background-color: #656565;
            position: absolute;
            top: 50%;
            margin-top: -15px;
            left: 0;
        }
    }
}
.home-left-sider::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    -webkit-appearance: none;
    opacity: 0 !important;
    padding-top: 54px;
}

html .right-bottom {
    width: 50px;
    height: @layout-header-height;
    line-height: @layout-header-height;
    text-align: center;
    color: white;
    &:hover {
        background: #000;
    }
}

.right-click-menu-text {
    margin-left: 10px;
}

// .ant-tabs-ink-bar{
//     // display: none !important;
// }

.userinfo {
    cursor: auto;
    padding: 0;
    dl {
        margin: 0;
        padding: 12px;
        dt {
            font-size: 16px;
            padding: 0 12px;
            color: #000;
            line-height: 26px;
            min-height: 26px;
        }
        dd {
            margin: 0;
            padding: 0 12px;
            white-space: nowrap;
            line-height: 22px;
        }
    }
    &:hover {
        background-color: transparent;
    }
}

.icon-trans:before {
    transition: all 0.5s;
}
.icon-style:before {
    transition: all 0.5s;
    transform: rotate(-180deg);
}

.layout_header {
    width: 100%;
    padding: 0;
    z-index: 120;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;

    &::after {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 3px;
        background-color: #3d3d3d;
        left: 0;
        bottom: 0;
        z-index: 99999;
    }

    .logo {
        padding-left: 20px;
        width: 170px;
        height: 100%;
        line-height: @layout-header-height;
        img {
            max-width: 106px;
            margin-right: 10px;
            max-height: 110%;
        }
        .logo_title {
            display: inline-block;
            color: #ccc;
        }
    }

    .mid_con {
        flex-grow: 1;
        // width: 360px;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        &::after {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 3px;
            background-color: #3d3d3d;
            left: 0;
            bottom: 0;
            z-index: 99999;
        }

        .mid_list {
            position: relative;
            flex-grow: 1;
            overflow: hidden;
            height: 100%;
            // margin: 0 8px;
        }

        .prev_btn,
        .next_btn {
            width: 28px;
            height: @layout-header-height;
            // background-color: #fff;
            cursor: pointer;
            flex-shrink: 0;
            z-index: 9999;
            transition: all 200ms;
            line-height: 48px;
            text-align: center;
            font-size: 14px;
            color: #fff;
            border: 1px solid black;
            &:hover {
                background-color: black;
                border: 1px solid #333;
            }
        }

        .prev_btn.show,
        .next_btn.show {
            transform: translateX(0);
        }

        .prev_btn.hide {
            transform: translateX(-100%);
        }

        .next_btn.hide {
            transform: translateX(100%);
        }
    }

    .right_con {
        flex-shrink: 0;
    }
}

/* tab栏样式 */
.global_tab_list {
    display: flex;
    height: 100%;
    align-items: flex-end;
    user-select: none;
    padding-bottom: 3px;
    // flex-grow: 1;
    flex-grow: 0;
    position: absolute;
    overflow: hidden;
    bottom: 0;
    transition: left 200ms;
}

.global_header_tab_item {
    width: 140px;
    height: 38px;
    perspective: 20px;
    position: relative;
    color: rgba(255, 255, 255, 0.36);
    font-size: 12px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8px;
    padding-left: 20px;
    padding-right: 30px;
    transition: color 200ms;
    flex-shrink: 0;
    // overflow: hidden;

    &:not(:first-child) {
        margin-left: -20px;
    }

    .trapezoid {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #282828;
        border-radius: 8px 8px 0px 0px;
        transform: rotateX(10deg);
        transform-origin: 50% 100%;
        box-shadow: 0 0 2px 3px rgba(0, 0, 0, 0.2);
    }

    .title {
        position: relative;
        z-index: 99;
        height: 16px;
        line-height: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .close {
        position: absolute;
        right: 14px;
        color: rgba(255, 255, 255, 0.5);
        z-index: 100;
        padding: 0 5px;
        font-size: 14px;
        height: 28px;
        line-height: 28px;

        &:hover {
            color: #fff;
        }
    }

    &.active {
        color: rgba(255, 255, 255, 0.9);
        z-index: 9999;

        .trapezoid {
            background-color: #3d3d3d;
        }
    }

    &:not(.active):hover {
        .trapezoid {
            background-color: #2f2f2f;
        }

        .title {
            color: rgba(255, 255, 255, 0.9);
        }
    }
}

.global_header_sort_drag_tab_item {
    .global_header_tab_item;

    z-index: 9998 !important;
}

.right-click-menu-text {
    margin-left: 10px;
}

.layout_content {
    // position: absolute;
    padding-top: @layout-header-height;
    // right: 0;
    // bottom: 0;
    overflow: hidden;
    flex: 1;

    .root_con {
        // position: absolute;
        // bottom: 0;
        // top: 0;
        // left: 0;
        // right: 0;
        // overflow: hidden;
        // transform: translateY(-100%);
        display: none;
        &.active {
            // transform: translateY(0);
            display: block;
        }

        .con_scroll {
            width: 100%;
            height: 100%;
            padding: 10px 10px 0;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}
.ant-menu-submenu-title {
    color: rgba(0, 0, 0, 0.6);
}

.tab-no-margin-bottom {
    .ant-tabs-bar {
        margin-bottom: 0;
    }
}
