html {
    .BaseTable {
        font-size: 12px;
        // min-height: 100px;
        .BaseTable__header {
            border-bottom: 1px solid #cacaca;
        }
        .BaseTable__header-cell {
            background-image: linear-gradient(#fff, #f0f0f0);
        }
        .BaseTable__header-cell-text {
            color: rgba(0, 0, 0, 0.65);
            font-weight: bold;
        }
        .BaseTable__row-cell-text {
            color: rgba(0, 0, 0, 0.65);
            width: 100%;
        }
        .BaseTable__row {
            &:nth-child(odd) {
                background: #fff;
            }
            &:nth-child(even) {
                background: rgba(0, 0, 0, 0.03);
            }
        }
        .BaseTable__row--hovered {
            background: rgba(255, 186, 48, 0.2) !important;
        }
        .BaseTable__table-frozen-left {
            .BaseTable__row--hovered {
                background: rgba(255, 186, 48, 0.2) !important;
            }
        }
        .BaseTable__table-main {
            outline: 1px solid #cacaca;
        }
    }
    ._isBorder {
        .BaseTable__header-cell,
        .BaseTable__row-cell {
            border-right: 1px solid #eee;
            border-top: 0;
        }
    }
    .BaseTable__table_selection_header,
    .BaseTable__table_selection {
        padding-right: 15px;
    }
    .BaseTable__table-frozen-right .BaseTable__row-cell:last-child {
        padding: 0px;
    }
    .BaseTable__table_action_header,
    .BaseTable__table_action {
        padding-left: 7.5px !important;
    }
    .ant-table-wrapper {
        .BaseTable__table .BaseTable__body {
            font-size: 12px;
            ::-webkit-scrollbar {
                -webkit-appearance: none;
                background-color: #e3e3e3;
            }

            ::-webkit-scrollbar:vertical {
                width: 10px;
            }

            ::-webkit-scrollbar:horizontal {
                height: 10px;
            }

            ::-webkit-scrollbar-thumb {
                border-radius: 10px;
                border: 2px solid #e3e3e3;
                background-color: #999;

                &:hover {
                    background-color: #666;
                }
            }

            ::-webkit-resizer {
                display: none;
            }
        }
    }

    // 表格喊过滤列表头
    .global_header_column_filters {
        .con {
            display: flex;
            line-height: 18px;

            & > i {
                margin-left: 8px;
                padding-top: 3px;
            }
        }
    }

    .global_header_column_filters_pop {
        .ant-popover-inner-content,
        .ant-popover-message {
            padding: 0;
        }
        .ant-popover-message-title {
            padding: 4px 0;
        }

        .ant-checkbox-group {
            width: 100%;
            padding-left: 10px;
        }

        .ant-checkbox-group-item {
            display: block;
            margin: 0;
            height: 30px;
            line-height: 30px;
        }

        .ant-popover-buttons .ant-btn {
            margin: 0;
            border-radius: 0;
        }
    }
}

@primary-color: #FFBA30;@link-color: #FFBA30;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 12px;@layout-header-background: #191919;@layout-body-background: #eee;@zindex-message: 9999;@btn-height-base: 26px;@input-height-base: 26px;@select-height-base: 26px;@menu-collapsed-width: 40px;@layout-header-height: 46px;@disabled-color: fade(#000, 55%);