@import "~antd/lib/style/themes/default.less";
.custom-modal {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.15);
    z-index: 100;
    right: 0;
    overflow: hidden;
}
.custom-modal-box {
    background: white;
    min-width: 500px;
    position: relative;
}
.custom-modal-content {
    overflow: auto;
    position: relative;
}
.custom-modal-close {
    display: inline-block;
    margin-left: auto;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
}
.custom-modal-close:hover {
    background: rgba(255, 255, 255, 0.1);
}
.custom-modal-title {
    // border-bottom: 1px solid #eee;
    background: #313131;
    color: white;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.217647), 0 1px 4px rgba(0, 0, 0, 0.217647);
}
.custom-modal-footer {
    border-top: 1px solid #eee;
    padding: 5px 10px;
    text-align: left;
}
.custom-modal-footer > div,
.custom-modal-footer > button {
    margin-left: 10px;
}

@primary-color: #FFBA30;@link-color: #FFBA30;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 12px;@layout-header-background: #191919;@layout-body-background: #eee;@zindex-message: 9999;@btn-height-base: 26px;@input-height-base: 26px;@select-height-base: 26px;@menu-collapsed-width: 40px;@layout-header-height: 46px;@disabled-color: fade(#000, 55%);