@import "~antd/es/style/themes/default.less";
.login-wrapper {
    color: #aaaaaa;
    //background: url('~@src/libs/img/login-bg.jpg') center no-repeat;
    // background-size: 100% 100%;
    position: relative;
    height: 100vh;
    overflow: hidden;
    width: 100%;
    // .bg-gif{
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     background: url('~@src/libs/img/login-bg.gif') no-repeat;
    //     background-position: left;
    //    // background-size: 100% 100%;
    // }
    section {
        height: 100vh;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .login-wrapper-content {
        color: #aaaaaa;
        position: relative;
        height: 100vh;
        overflow: hidden;
        width: 100%;
    }
    video {
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.8);
        top: 0;
    }
    section:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .logo {
        // position: absolute;
        width: 170px;
        left: 40px;
        top: 40px;
    }
    .login-title {
        position: absolute;
        left: 60px;
        top: 150px;
        font-size: 22px;
        color: #fff;
        text-align: center;
    }
    .right-wrapper {
        position: absolute;
        right: 150px;
        .page-login {
            height: 100%;
            justify-content: center;
            width: 360px;
            background-color: #fff;
            padding: 20px 20px 30px;
            border-radius: 4px;
            box-shadow: 0 0 2px 2px rgba(170, 170, 170, 0.1);
            ul {
                padding: 0;
                margin: 0;
                margin-bottom: 10px;
            }
            .user-type {
                .user-title {
                    color: #999;
                    font-size: 14px;
                    text-align: center;
                    cursor: pointer;
                    border-left: 1px solid #cbc3ac;
                    margin-right: 15px;
                    &:last-child {
                        margin-right: 0;
                    }
                    .icon {
                        margin-right: 2px;
                        width: 24px;
                        height: 24px;
                    }
                }
                .selected {
                    border: 1px solid @primary-color;
                    color: @primary-color;
                    padding: 7px 0;
                }
            }
            ul.tab_title {
                display: flex;
                border-bottom: 1px solid #ddd;
                margin-bottom: 30px;
                position: relative;

                &::after {
                    position: absolute;
                    content: "";
                    display: block;
                    width: 50%;
                    height: 3px;
                    background-color: @primary-color;
                    bottom: 0;
                    transition: all 200ms;
                }

                &.left::after {
                    left: 0;
                }
                &.right::after {
                    left: 50%;
                }

                li {
                    width: 50%;
                    text-align: center;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.65);
                    height: 38px;
                    line-height: 38px;
                    cursor: pointer;
                    font-weight: 600;
                    &.active {
                        color: @primary-color;
                    }
                }
            }

            .login-form {
                .ant-input-group .ant-input {
                    height: 40px;
                    padding-left: 70px;
                }
                .ant-form-item {
                    margin-bottom: 20px;
                }
                .padding-left {
                    .ant-input {
                        height: 40px;
                        padding-left: 70px;
                    }
                    .ant-input:-webkit-autofill {
                        box-shadow: 0 0 0 1000px white inset !important;
                        background-color: #fff !important;
                    }
                }
                .form_code {
                    .ant-input-group-addon {
                        &::before {
                            display: none;
                        }
                    }
                    .send_text,
                    .seconds_text,
                    .loading_text {
                        font-size: 12px;
                    }
                    .send_text {
                        color: @primary-color;
                        cursor: pointer;
                    }
                }

                .ant-checkbox-inner {
                    border-color: #ccc;
                }

                .login-form-button {
                    height: 42px;
                    width: 100%;
                }
            }
        }
    }
}

@primary-color: #FFBA30;@link-color: #FFBA30;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 12px;@layout-header-background: #191919;@layout-body-background: #eee;@zindex-message: 9999;@btn-height-base: 26px;@input-height-base: 26px;@select-height-base: 26px;@menu-collapsed-width: 40px;@layout-header-height: 46px;@disabled-color: fade(#000, 55%);