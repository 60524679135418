.hoverDetail {
    .flex() {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    display: flex;
    flex-direction: column;
    background-color: rgba(58, 63, 74, 0.9);
    width: 250px;
    min-height: 50px;
    padding: 10px;
    box-sizing: border-box;
    .info {
        .flex();
        align-items: flex-start;
        .normal {
            font-size: 12px;
            color: rgb(255, 255, 255);
            word-wrap: break-word;
            word-break: break-all;
        }
    }
    .detail {
        .flex();
        flex: 1;
        padding: 7px 0 0;
        box-sizing: border-box;
        .item {
            display: flex;
            flex: 1;
            .normal {
                font-size: 12px;
                color: rgb(255, 255, 255);
            }
            & + .item {
                &::before {
                    content: "";
                    display: block;
                    width: 1px;
                    height: 10px;
                    margin: 5px 10px 0 0;
                    background-color: #fff;
                }
            }
        }
    }
}

@primary-color: #FFBA30;@link-color: #FFBA30;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 12px;@layout-header-background: #191919;@layout-body-background: #eee;@zindex-message: 9999;@btn-height-base: 26px;@input-height-base: 26px;@select-height-base: 26px;@menu-collapsed-width: 40px;@layout-header-height: 46px;@disabled-color: fade(#000, 55%);