.ant-modal-header {
    padding: 10px 24px;
    border-radius: 0;
    background: #313131;
    color: #fff;
    // border-bottom: 1px solid #e8e8e8;
}
.ant-modal-title {
    color: #fff;
}

.ant-modal-close-x {
    &:hover {
        color: #ccc;
    }
    display: block;
    font-style: normal;
    vertical-align: baseline;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
    width: 41px;
    height: 41px;
    line-height: 41px;
    font-size: 16px;
    color: #fff;
}

@primary-color: #FFBA30;@link-color: #FFBA30;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 12px;@layout-header-background: #191919;@layout-body-background: #eee;@zindex-message: 9999;@btn-height-base: 26px;@input-height-base: 26px;@select-height-base: 26px;@menu-collapsed-width: 40px;@layout-header-height: 46px;@disabled-color: fade(#000, 55%);