li {
    list-style: none;
    padding: 0;
    margin: 0;
}

p {
    position: relative;
    line-height: 1.4em;
    /* 3 times the line-height to show 3 lines */
    overflow: hidden;
}

dl,
dt,
dd {
    margin: 0;
    padding: 0;
}

*,
:after,
:before {
    box-sizing: border-box;
}
