.awesome {
    width: 300px;
    transition: all 0.5s ease-in-out;
    transform: translateX(300px);
}
.awesome_card {
    @bd: 1px solid rgba(62, 90, 164, 1);
    @bColor: rgba(62, 90, 164, 1);

    position: relative;
    width: 300px;
    z-index: 10;
    // transition: all .5s ease-in-out;
    // transform: translateX(300px);
    &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 32px;
        overflow: hidden;
        .left {
            display: flex;
            width: 40%;
            height: 100%;
            .angle {
                width: 8px;
                border-left: @bd;
                margin-top: 8px;
                position: relative;
                flex-shrink: 0;
                background-color: rgba(16, 16, 16, 0.8);
                &::before {
                    content: "";
                    position: absolute;
                    left: -1px;
                    top: -16px;
                    display: block;
                    background-color: @bColor;
                    width: 1px;
                    height: 16px;
                    transform: rotate(45deg);
                    transform-origin: 0 100%;
                }
                &::after {
                    content: "";
                    position: absolute;
                    left: 0px;
                    top: -8px;
                    width: 0;
                    height: 0;
                    border-top: 0px solid transparent;
                    border-left: 7px solid transparent;
                    border-right: 0px solid rgba(16, 16, 16, 0.8);
                    border-bottom: 8px solid rgba(16, 16, 16, 0.8);
                }
            }
            .title {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                border-top: @bd;
                font-size: 14px;
                color: #fff;
                background-color: rgba(16, 16, 16, 0.8);
            }
        }
        .right {
            display: flex;
            width: 60%;
            height: 100%;
            .angle {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    left: 7px;
                    bottom: -4px;
                    display: block;
                    width: 1px;
                    height: 50px;
                    background-color: @bColor;
                    transform-origin: 50% 50%;
                    transform: rotate(-36deg);
                }
                &::after {
                    content: "";
                    position: absolute;
                    right: -24px;
                    top: 0px;
                    width: 0;
                    height: 0;
                    border-top: 1px solid transparent;
                    border-left: 0px solid transparent;
                    border-right: 24px solid transparent;
                    border-bottom: 32px solid rgba(16, 16, 16, 0.8);
                }
            }
            .blank {
                flex: 1;
                border-bottom: @bd;
                margin: 0 12px 0 22px;
            }
            .title {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                font-size: 14px;
                color: #fff;
                border-bottom: @bd;
                margin: 0 0 0 22px;
                background-color: #33436e;
                cursor: pointer;
                &::before {
                    content: "";
                    position: absolute;
                    left: -22px;
                    top: 0;
                    width: 0;
                    height: 0;
                    border-top: 33px solid #33436e;
                    border-left: 20px solid transparent;
                    border-right: 20px solid transparent;
                    border-bottom: 0px solid transparent;
                }
            }
            .route_blank {
                flex-shrink: 0;
                margin-right: 10px;
                width: 20px;
                position: relative;
                border-bottom: @bd;
                cursor: pointer;
                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: -2px;
                    width: 0;
                    height: 0;
                    border-top: 0px solid transparent;
                    border-left: 0 solid transparent;
                    border-right: 20px solid transparent;
                    border-bottom: 33px solid #33436e;
                }
            }
        }
    }
    &_content {
        width: calc(100% - 12px);
        border: @bd;
        border-top: none;
        border-bottom: none;
        padding: 10px 10px 0;
        box-sizing: border-box;
        min-height: 50px;
        max-height: 500px;
        background-color: rgba(16, 16, 16, 0.8);

        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            display: none; /* Chrome Safari */
        }
        -ms-overflow-style: none; /* IE 10+ */
        scrollbar-width: none; /* Firefox */
        .item {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 2px;
            .normal {
                font-size: 12px;
                color: gray;
            }
            .white {
                color: #fff;
            }
        }
        .stowage_item {
            width: 100%;
            background-color: #52525299;
            padding: 5px;
            box-sizing: border-box;
            cursor: pointer;
            &_title {
                width: 100%;
                display: flex;
                justify-content: space-between;
                color: #fff;
                font-size: 12px;
                margin-bottom: 6px;
                .status {
                    color: #73c373;
                    font-size: 12px;
                }
            }
            &_info {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: #b3b3b3;
                font-size: 12px;
                .left {
                    width: 70px;
                    flex-shrink: 0;
                }
                .right {
                }
            }
            & + .stowage_item {
                margin-top: 10px;
            }
            &.active {
                border: 1px solid rgba(33, 150, 243, 80%);
                box-shadow: rgb(33, 150, 243) 0px 0px 5px;
                background-color: rgba(33, 150, 243, 27%);
            }
        }
        .route_item {
            display: flex;
            align-items: center;
            width: 100%;
            color: #fff;
            font-size: 12px;
            .point {
                width: 8px;
                height: 8px;
                border-radius: 8px;
                background-color: #616161;
                margin: 0 8px;
            }
            & + .route_item {
                margin-top: 15px;
                .point {
                    position: relative;
                    // &::before {
                    //     content: '';
                    //     display: block;
                    //     position: absolute;
                    //     width: 2px;
                    //     height: 50px;
                    //     left: 3px;
                    //     top: -45px;
                    //     background-color: #616161;
                    // }
                }
            }
            & + .route_item::after {
                content: "";
                display: block;
                position: absolute;
                width: 2px;
                height: calc(100% - 40px);
                left: 84px;
                top: 20px;
                background-color: #616161;
            }
            .right {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                height: 38px;
                .route_address {
                    color: #666;
                    width: 155px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }
        .not_found {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 35px;
            font-size: 16px;
            color: #fff;
        }
        .path_item {
            display: flex;
            align-items: center;
            width: 100%;
            color: #fff;
            font-size: 12px;
            position: relative;
            .point {
                width: 20px;
                height: 20px;
                border-radius: 8px;
                background-color: @bColor;
                margin: 0 8px;
                border-radius: 50%;
                text-align: center;
                flex-shrink: 0;
                position: relative;
                z-index: 3;
            }
            .text {
                padding: 5px;
                border-radius: 5px;
                background-color: #16556985;
                position: relative;
                margin-left: 5px;
                .address_path {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .triangle {
                    display: block;
                    width: 0;
                    height: 0;
                    border-width: 11px;
                    border-style: solid;
                    border-color: transparent rgba(0, 0, 0, 0.11) transparent transparent;
                    position: absolute;
                    left: -23px;
                    top: 50%;
                    &::after {
                        content: "";
                        display: block;
                        width: 0;
                        height: 0;
                        border-width: 9px;
                        border-style: solid;
                        border-color: transparent #16556985 transparent transparent;
                        position: absolute;
                        left: -6px;
                        top: -20px;
                    }
                }
            }
            & + .path_item {
                margin-top: 15px;
                position: relative;
                &::before {
                    // content: '';
                    // display: block;
                    // position: absolute;
                    // width: 2px;
                    // height: calc(100% + 20px);
                    // left: 17px;
                    // top: -32px;
                    // background-color: @bColor;
                }
            }
            & + .path_item::after {
                content: "";
                display: block;
                position: absolute;
                width: 2px;
                height: calc(100% + 10px);
                left: 18px;
                top: -25px;
                background-color: #3e5aa4;
            }
        }
    }
    &_route {
        position: relative;
        // &::after {
        //     content: '';
        //     display: block;
        //     position: absolute;
        //     width: 2px;
        //     height: calc(100% - 50px);
        //     left: 94px;
        //     top: 33px;
        //     background-color: #616161;
        // }
    }
    &_path {
        position: relative;
        max-height: 700px;
        // &::after {
        //     content: '';
        //     display: block;
        //     position: absolute;
        //     width: 2px;
        //     height: calc(100% - 55px);
        //     left: 27px;
        //     top: 31px;
        //     background-color: #3e5aa4;
        // }
    }
    &_bottom {
        display: flex;
        height: 32px;
        .blank {
            position: relative;
            width: calc(100% - 44px);
            border-bottom: @bd;
            border-left: @bd;
            background-color: rgba(16, 16, 16, 0.8);
            &::after {
                content: "";
                position: absolute;
                right: 0;
                bottom: 0;
                width: 1px;
                height: 45px;
                background-color: #3e5aa4;
                transform: rotate(45deg);
                transform-origin: 0 100%;
            }
            &::before {
                content: "";
                position: absolute;
                right: -30px;
                bottom: 0px;
                width: 0;
                height: 0;
                border-top: 31px solid rgba(16, 16, 16, 0.8);
                border-left: 0px solid rgba(16, 16, 16, 0.8);
                border-right: 30px solid transparent;
                border-bottom: 0px solid transparent;
            }
        }
    }
    &_show {
        position: absolute;
        left: -18px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 51px;
        background-color: rgba(16, 16, 16, 100);
        cursor: pointer;
    }
}
.map_footer {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 42px;
    background-color: #383838;
    box-sizing: border-box;
    .item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        border-right: 1px solid #616161;
        .normal {
            color: rgba(255, 255, 255, 100);
            font-size: 12px;
            margin-right: 10px;
        }
        .number {
            color: rgba(255, 153, 89, 100);
            font-size: 16px;
        }
    }
}
.awesome_header {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 42px;
    padding: 0 10px;
    box-sizing: border-box;
    background-color: rgba(16, 16, 16, 0.8);
    z-index: 10;
    
    .btns {
    }
}
.way_path {
    width: 300px;
    transition: all 0.5s ease-in-out;
    transform: translateX(-300px);
    & > .awesome_card_show {
        left: auto;
        right: -6px;
    }
}

@primary-color: #FFBA30;@link-color: #FFBA30;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 12px;@layout-header-background: #191919;@layout-body-background: #eee;@zindex-message: 9999;@btn-height-base: 26px;@input-height-base: 26px;@select-height-base: 26px;@menu-collapsed-width: 40px;@layout-header-height: 46px;@disabled-color: fade(#000, 55%);