.btn-wrapper {
    position: relative;
    width: 68px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #444444;
    height: 24px;
    line-height: 24px;
    padding: 0 15px;
    margin-right: 10px;
    cursor: pointer;
    .btn-mask {
        position: absolute;
        background: #fff;
        height: 5px;
        top: 40px;
        left: 0;
        width: 100%;
    }
}
.btn-main {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    padding: 10px 10px;
    transition: all 0.5s;
    margin-bottom: 10px;
    border-top: 0;
    -webkit-box-shadow: 0 3px 6px -3px #00000059;
    box-shadow: 0 3px 6px -3px #00000059;
}

@primary-color: #FFBA30;@link-color: #FFBA30;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 12px;@layout-header-background: #191919;@layout-body-background: #eee;@zindex-message: 9999;@btn-height-base: 26px;@input-height-base: 26px;@select-height-base: 26px;@menu-collapsed-width: 40px;@layout-header-height: 46px;@disabled-color: fade(#000, 55%);