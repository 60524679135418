// 主题色
.global_primary_color {
    color: @primary-color;
}

.global_primary_bgc {
    background-color: @primary-color;
}

.global_primary_bd {
    border: 1px solid @primary-color;
}

.global_primary_bdc {
    border-color: @primary-color;
}

.global_click_text {
    .global_primary_color();
    cursor: pointer;
}

.global_hover_text {
    .global_primary_color();
    user-select: none;
}

.global_click_underline_text {
    .global_primary_color();
    cursor: pointer;
    text-decoration: underline;
}

.global_ml10 {
    margin-left: 10px;
}

.global_mr10 {
    margin-right: 10px;
}

.global_handsome_layoutlr {
    display: flex;
    width: 100%;
    overflow: hidden;
    position: relative;

    .left {
        height: 100%;
        flex-shrink: 0;
        position: relative;
        transition: width 200ms;
        overflow-x: hidden;
        overflow-y: auto;
        border-right: 1px solid #e0e0e0;
        overflow: hidden;

        .default_title {
            height: 38px;
            padding: 0 14px 0 20px;
            line-height: 38px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
                font-size: 14px;
                color: #3a3834;
                font-weight: bold;
            }
        }

        .default_list {
            margin: 0;
            padding: 0;
            overflow: hidden;
            margin-top: 5px;

            li {
                display: flex;
                font-size: 12px;
                height: 25px;
                align-items: center;
                overflow: hidden;
                line-height: 1.2;
                padding: 0 10px 0 20px;

                .label {
                    width: 60px;
                    flex-shrink: 0;
                    color: #808080;
                    margin-right: 20px;
                }

                .desc {
                    color: #444;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }

    & > .collapsebtn {
        position: absolute;
        width: 15px;
        height: 36px;
        z-index: 99;
        top: 50%;
        margin-top: -36px;
        line-height: 36px;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        cursor: pointer;
        border-radius: 10px 0px 0px 10px;
        transform: rotate(0);
        transition: left 200ms;
        display: flex;
        align-items: center;
        justify-content: center;

        &.collapsed {
            transform: rotate(180deg);
        }

        &:hover {
            .global_primary_color();
        }
    }

    .right {
        height: 100%;
        flex-grow: 1;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.global_handsome_headbar {
    width: 100%;
    overflow: hidden;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    padding: 0 10px 0 20px;
    align-items: center;
    justify-content: space-between;

    .title {
        color: #484848;
        font-size: 14px;
        font-weight: bold;
    }
}

.global_handsome_tag {
    text-align: center;
    font-size: 12px;
    display: inline-block;
    font-weight: 400;
    height: 20px;
    // line-height: 20px;
    padding: 0 4px;
    border-radius: 4px;
    vertical-align: middle;
    font-family: PingFangSC-Regular, PingFang SC;
    user-select: none;
}

.global_proportionbar {
    display: flex;
    overflow: hidden;
    font-size: 12px;
    height: 14px;
    line-height: 14px;
    color: #1e1e1e;
    flex-grow: 1;
    user-select: none;

    .bar {
        height: 100%;
        border-radius: 7px;
        background-color: #e7e7e7;
        flex-shrink: 0;
        display: flex;
        flex-grow: 1;

        & > div {
            background-color: @primary-color;
            text-align: center;
            white-space: nowrap;
            overflow: visible;
            // text-overflow: ellipsis;
        }
    }

    .sum {
        height: 100%;
        margin-left: 12px;
    }
}

.global_max_page_width {
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
}

.global_scroll_dropdown {
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #c4c4c4;
}

.global_popover_con_notpadding {
    .ant-popover-inner-content {
        padding: 0;
    }
}

html {
    // 详情页tabs样式
    .global_details_page_tabs {
        // height: 100%;

        & > .ant-tabs-bar {
            margin-bottom: 0;
            background-color: #fafafa;
            // border-top: 1px solid #e8e8e8;
        }

        &.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
            height: 30px;
            line-height: 28px;
            border-radius: 0;
            border-left: 0;
            border-top: 0;
            margin-right: 0;
        }

        &.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container,
        .ant-tabs-tab-active {
            height: 30px;
        }

        &.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane,
        .ant-tabs.ant-tabs-editable-card .ant-tabs-card-content > .ant-tabs-tabpane {
            padding-bottom: 1px;
        }
    }
}
.table-filter-item {
    display: flex;
    align-items: center;
    padding: 5px;
    &:hover {
        color: @primary-color;
        background: #ddd;
        .table-filter-item-sort {
            opacity: 1;
        }
    }
}
.table-filter-item-overlay {
    .ant-popover-inner-content {
        padding: 5px;
    }
}

.advance-table-filter-item-overlay {
    .ant-popover-inner-content {
        padding: 0px;
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin: 0;
        margin-top: 5px;
    }
}

.carrier-type-item {
    border: 1px solid #eee;
    border-right: 0;
    padding: 5px;
    cursor: pointer;
}

.carrier-type-item:last-child {
    border-right: 1px solid #eee;
}

.carrier-type-item-active :last-child {
    border-right: 1px solid @primary-color;
}

.carrier-type-item-active {
    color: @primary-color;
    // .carrier-type-item {
    border: 1px solid @primary-color;
    border-right: 1px solid @primary-color !important;
    // border-right: 0;
    // }
    // .carrier-type-item:last-child {
    //     border-right: 1px solid @primary-color;;
    // }
}
.action-button-menu {
    padding: 5px 12px;
    width: 100%;
    display: inline-block;
}
p {
    margin: 0;
    padding: 0;
}
