.tag-wrapper {
    color: rgba(0, 0, 0, 0.65);
    font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
    box-sizing: border-box;
    margin: 0;
    list-style: none;
    display: inline-block;
    height: 24px;
    min-width: 24px;
    // line-height: 24px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    background: #fafafa;
    font-size: 12px;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    margin-right: 8px;
    cursor: pointer;
    white-space: nowrap;
    padding: 0 5px;
}
.tag-wrapper {
    .anticon {
        font-size: 12px;
        font-style: normal;
        text-align: center;
        text-transform: none;
        // line-height: 1;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
    }
    .anticon:hover {
        color: rgba(0, 0, 0, 0.95);
    }
}

@primary-color: #FFBA30;@link-color: #FFBA30;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 12px;@layout-header-background: #191919;@layout-body-background: #eee;@zindex-message: 9999;@btn-height-base: 26px;@input-height-base: 26px;@select-height-base: 26px;@menu-collapsed-width: 40px;@layout-header-height: 46px;@disabled-color: fade(#000, 55%);