.multi-select-popover {
    .ant-popover-inner-content {
        padding: 0;
        .ant-menu-root {
            max-height: 200px;
            overflow: auto;
            .ant-menu-item {
                height: 30px;
                line-height: 30px;
                margin: 0;
            }
        }
    }
}

@primary-color: #FFBA30;@link-color: #FFBA30;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 12px;@layout-header-background: #191919;@layout-body-background: #eee;@zindex-message: 9999;@btn-height-base: 26px;@input-height-base: 26px;@select-height-base: 26px;@menu-collapsed-width: 40px;@layout-header-height: 46px;@disabled-color: fade(#000, 55%);